const hover = document.querySelector(".hover");
const hoverWHalf = hover.offsetWidth / 2.5;
const container = document.querySelector(".bg-cover");

container.addEventListener("mousemove", (e) => {
  hover.style.left = e.pageX - hoverWHalf + "px";
  hover.style.top = e.pageY - hoverWHalf + "px";
  hover.style.opacity = "1";
}, {passive: true, capture: true});

