require('../css/home.scss');
require('../css/main.scss');

const mobileMenu = require('./func/mobile-menu');
const coverHoverEffect = require('./func/cover-hover');
const contactForm = require('./func/contact-index');
const currentYear = require('./func/current-year');
const cookiesConsent = require('./func/cookies-consent');



window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    mobileMenu();
}
