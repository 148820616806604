require('vanilla-cookieconsent/dist/cookieconsent');
require('vanilla-cookieconsent/dist/cookieconsent.css');
const cc = initCookieConsent();

cc.run({
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
        console.log('cookies -> onFirstAction');
    },

    onAccept: function (cookie) {
        // ...
        console.log('cookies -> onAccept');
    },

    onChange: function (cookie, changed_preferences) {
        // ...
        console.log('cookies -> onChange');
    },

    gui_options: {
        consent_modal: {
            layout: 'bar', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            transition: 'slide' // zoom/slide
        }
    },

    languages: {
        en: {
            consent_modal: {
                description:
                    'We use cookies and other tracking technology to enhance your experience with our website and to make your visit to our website more enjoyable. Full details can be found under “Cookie Settings” and in our <a href="/cookies-policy.html">Cookie Policy</a>.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Cookie Settings',
                    role: 'settings' // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: '<div class="setting-title">Manage your data</div><div class="setting-info">We would like you to be in control of the information that different categories of cookies collect. Below you can manage your cookie preferences.</div>',
                save_settings_btn: 'Save Settings',
                accept_all_btn: 'Accept all',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: 'Essential cookies (required)',
                        description:
                            'These cookies are necessary for the functioning of the Website and cannot be switched off in our systems. They are usually set in response to actions made by you, such as setting your privacy preferences, logging in, or filling out forms.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: 'Analytical',
                        description:
                            'These cookies allow us to recognize and count the number of visitors to the Website and see how visitors move around. This helps us improve the way the Website works, for example, by ensuring that users can easily find what they are looking for. <ul><li><strong>Google Analytics</strong></li></ul>',
                        toggle: {
                            value: 'analytics-ga', // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    },
                    {
                        description:
                            '<div class="settings-additional-info"></div>'
                    }
                ]
            }
        }
    }
});
