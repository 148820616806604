window.addEventListener('DOMContentLoaded', run);

import handleFormSubmit from './contact-form';

function run(e) {
    handleFormSubmit({
        selector: '.contact-form',
        uri: 'https://contact.zaia.dev/api/contact'
    });
}
